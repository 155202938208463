<!--<style scoped lang="scss">-->
<!--@import './DropdownCurrencyStyle.scss';-->
<!--</style>-->

<template>
  <div :class="[className, theme ? 'theme-long' : '']" v-ClickOutside="closeCurrenciesDropdown">
    <button
      type="button"
      :class="[`${className}&#45;&#45;button`, classButton, { active: showCurrenciesDropdown }, {has_border: showBorder}]"
      title="Select currency"
      aria-label="Select currency"
      @click="openCurrenciesDropdown"
    >
      <span :class="['fs-12 lh-14 pr-10', theme ? 'fs-16' : '']">
        <span :class="classButtonText ? classButtonText : ['fw-700 black', theme ? 'dib w-45 tl fs-16 lh-24 pr-10' : '']">{{ showCodeCurrent ? selectedCurrency.code : selectedCurrency.symbol}}</span>
        <span v-if='theme' :class="['fw-500 fs-14 lh-20']" style='color: #2D3748'>{{ selectedCurrency.label }}</span>
      </span>
      <img class='icon' :src="`${ASSETS_CDN}/vectors/general/icon-chevron-down.svg`" alt='chevron down'/>
    </button>
    <div :class="[`${className}--dropdown`, { active: showCurrenciesDropdown }]">
      <ul>
        <li v-for="(item, index) in STATIC_CURRENCIES" :key="`header-currencies-${index}`">
          <button
            type="button"
            :title="`Select currency: ${item.label}`"
            :aria-label="`Select currency: ${item.label}`"
            @click="changeCurrency(item)"
          >
            <span :class="[theme ? 'dib w-45 tl fs-16 lh-24 fw-700 pr-10' : '']">{{ showCodeCurrent ? item.code : item.symbol }}</span>
            <span :class="[theme ? 'fw-500 fs-14 lh-20' : '']">&nbsp;{{ item.label }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from '~/directives/ClickOutside'
import { defineComponent, ref, computed } from 'vue'
import { useGetCookie } from '~/composables/common/useCustomCookie'
import currenciesApi from '~/composables/api/currenciesApi'
import { useRoute } from 'vue-router'
import { STATIC_CURRENCIES } from '~/enum/currency.enum'
import { debounceHeapTracking } from '~/mixins/heap'
import { debounceAmplitudeScriptTracking } from '~/mixins/amplitude'
import {assetCDN} from '~/helper/index.js'
import {useCurrenciesStore} from '~/store/pinia/currencies'

export default defineComponent({
  directives: {
    ClickOutside,
  },
  props: {
    theme: {type: String, default: ''},
    classButton: {type: String, default: ''},
    classButtonText: {type: String, default: ''},
    showCodeCurrent: {type: Boolean, default: true},
    showDropdownRight: {type: Boolean, default: true},
    showBorder: {type: Boolean, default: false}
  },
  setup(props) {
    const route = useRoute()
    const showCurrenciesDropdown = ref(false)
    // const currencyDropdownClick = ref(store.state.currenciesStore.currencyChangeByUser)
    const currencyLoaded = ref(false)
    const ASSETS_CDN = assetCDN()
    const currenciesStore = useCurrenciesStore()
    const currencyDropdownClick = ref(currenciesStore.currencyChangeByUser)

    const selectedCurrency = computed(() => {
      // return store.getters['currenciesStore/getCurrencySelected']
      return currenciesStore.getCurrencySelected
    })
    const className = computed(() => {
      return props.showDropdownRight ? 'right-currencies' : 'left-currencies'
    })

    // const currenciesList = computed(() => store.state.currenciesStore.currenciesData)
    const currenciesList = computed(() => currenciesStore.currenciesData)

    const changeCurrency = (item) => {
      const dj_curr = useGetCookie('dj_curr')
      const dj_curr_symbol = useGetCookie('dj_curr_symbol')
      currencyDropdownClick.value += 1
      debounceHeapTracking("Switch Currency", {
        current_currency: selectedCurrency.value.code,
        new_currency: item.code
      })
      debounceAmplitudeScriptTracking("Switch Currency", {
        current_currency: selectedCurrency.value.code,
        new_currency: item.code
      })
      closeCurrenciesDropdown()
      // setCookie('dj_curr', item.code, COOKIE.DURATION)
      // setCookie('dj_curr_symbol', item.symbol, COOKIE.DURATION)
      dj_curr.value = item.code
      dj_curr_symbol.value = item.symbol

      // console.log('changeCurrency',selectedCurrency.value.code,' == ',item)
      const selected = currenciesList.value.find(
        ({ code }) => code === item.code,
      )

      currenciesStore.setCurrencySelected(selected)
      currenciesStore.setCurrencyDropdownClick(currencyDropdownClick.value)

      currenciesApi.postCurrency(selected.code)
    }

    // const tripBooked = computed(() => store.state.currenciesStore.tripBooked)
    const tripBooked = computed(() => currenciesStore.tripBooked)
    const openCurrenciesDropdown = () => {
      if(route.name === 'trip-plan-bookable' || route.name === 'checkout-balance' || route.name === 'trip-preview') {
        if(tripBooked.value) showCurrenciesDropdown.value = false
        else {
          showCurrenciesDropdown.value = !showCurrenciesDropdown.value
        }
      } else {
        showCurrenciesDropdown.value = !showCurrenciesDropdown.value
      }
    }

    const closeCurrenciesDropdown = () => {
      showCurrenciesDropdown.value = false
    }

    return {
      currenciesList,
      changeCurrency,
      selectedCurrency,
      showCurrenciesDropdown,
      closeCurrenciesDropdown,
      openCurrenciesDropdown,
      STATIC_CURRENCIES,
      currencyLoaded,
      className,
      ASSETS_CDN
    }
  }
})
</script>
<style scoped lang='scss'>
  @import './DropdownCurrencyStyle.scss';
  
  .has_border {
   border: 1px solid brandColor(brand,950);
   border-radius: 9999px;
   padding: 4px 8px;
  }
</style>
